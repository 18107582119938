import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Coqui is a polyglot! Now we support multiple languages!`}</p>
    <ul>
      <li parentName="ul">{`Our emotive, immersive voices are now in `}<strong parentName="li">{`English, German, French, Spanish, Italian,
Portuguese, and Polish with more on the way!`}</strong></li>
      <li parentName="ul">{`All default voices now speak all supported languages! (Localization just got `}<em parentName="li">{`much`}</em>{`
easier.)`}</li>
      <li parentName="ul">{`Any XTTS clone can now speak any supported language! (Dubbing you’ve dreamt of!)`}</li>
      <li parentName="ul">{`Any XTTS voice you’ve built, either through prompt-to-voice or the voice-builder, now
speaks any supported language! (Magic!)`}</li>
    </ul>
    <p>{`Coqui, world leader in open source speech, just threw open its doors to the
languages of the world, adding support for `}<strong parentName="p">{`English, German, French, Spanish,
Italian, Portuguese, and Polish with more languages on the way!`}</strong></p>
    <div align="center">
      <Link to="https://app.coqui.ai/auth/signup" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "137px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.306569343065696%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMAGE",
              "title": "IMAGE",
              "src": "/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-coqui-the-polyglot-try-now-for-free.png",
              "srcSet": ["/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-coqui-the-polyglot-try-now-for-free.png 137w"],
              "sizes": "(max-width: 137px) 100vw, 137px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </Link>
    </div>
    <p>{`Whatever your use case (video game voice over, audio post production, dubbing,
localization, podcasts, audiobooks…) you’ll benefit from multiple languages;
multiply your audience many times over with the click of a button.`}</p>
    <div align="center">
  <iframe style={{
        "width": "640px",
        "height": "360px",
        "borderWidth": "0px 0px 0px 0px",
        "borderStyle": "solid solid solid solid",
        "borderColor": "black black black black"
      }} src="https://youtube.com/embed/mI9P7oIl_Uc?autoplay=1&loop=1&playlist=mI9P7oIl_Uc" allow="accelerometer;
        autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <p>{`All of the emotive, immersive default voices now speak all supported languages.
So (whether it’s Maja Ruoho, Gitta Nikolina, or any other default voice) they’re all
polyglots! Beyond that, any XTTS clone you’ve created is also, with no effort on
your part, a polyglot! So too are any XTTS voices you’ve built with prompt-to-voice
or the voice-builder. They’re all polyglots!`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      